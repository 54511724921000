.app {
  width: 100%;
}
.topbar,
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.topbar {
  width: 100%;
  background-color: rgb(119, 212, 253);
  padding: 0.5em 1em;
  height: 38px;
}
.navbar {
  height: 65px;
  margin-top: 38px;
  background-color: #fff;
  padding: 1em 0;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
}
.topbar-contents,
.nav-contents,
.menu-side-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-contacts-item {
  display: flex;
  align-items: center;
}
.top-contacts-item.mid-item {
  margin: 0 8px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0 8px;
}
.top-contacts-item img {
  width: 20px;
  margin-right: 4px;
}
.top-contacts-item span {
  font-size: 14px;
  color: #000;
}
.top-social a:hover {
  background-color: #0224ff;
}
.top-social a.mid-item {
  margin: 0 1.5em;
}
.top-social a img {
  width: 20px;
}
.brand-side {
  display: block;
  text-decoration: none;
  font-size: 1.5em;
  color: #0224ff;
}

.nav-label,
.hide-menu-btn,
#toggle-responsive-menu {
  display: none;
}
.menu-side .main-menu {
  display: flex;
  cursor: pointer;
}
.menu-side .main-menu > li {
  margin-right: 1.5em;
}
.menu-side ul.main-menu > li > a {
  text-decoration: none;
  color: #000;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
}
.menu-side ul.main-menu > li > a:hover,
.menu-side ul.main-menu > li > a:focus,
.dropdown ul li > a:hover {
  color: #0224ff;
  text-decoration: underline;
}
.nav-cta {
  background-color: #0224ff;
  color: #fff;
  width: auto;
}

.dropdown {
  position: relative;
}

.dropdown ul.dropdown-menu {
  display: none;
  flex-flow: column;
  border: 1px solid #77d4fd;
  border-bottom: 0;
  box-shadow: 0 0 3px 1px rgba(41, 69, 31, 0.3);
  position: absolute;
  top: 100%;
  width: 130px;
  height: auto;
  background-color: #fff;
  z-index: 10;
}

.dropdown ul li {
  padding: 10px;
  border-bottom: 1px solid #77d4fd;
}
.dropdown ul li > a {
  text-decoration: none;
  color: #77d4fd;
}

.dropdown:hover ul.dropdown-menu {
  display: flex;
  animation: animatezoom 1s;
}
.hide-responsive {
  display: none;
}
.hero {
  background-color: #fff;
  width: 100%;
  position: relative;
  margin-top: 98px;
  background-image: url("./assets/images/content/chart1.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.page-hero {
  margin-top: 98px;
}
.hero-logo {
  width: 100px;
  margin: auto;
  margin-bottom: 20px;
}

.carousel-content-container,
.carous .slider-items-container .carous-image img,
.hero-content-container {
  height: 80vh;
}
.carousel-content {
  position: absolute;
  z-index: 6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.carous .slider-items-container .carous-image img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.carous .slider-items-container {
  padding: 0 !important;
}

.hero-content-container {
  width: 100%;
  display: flex;
}

.hero-content-inner {
  margin: auto;
  width: 350px;
  background-color: rgba(41, 69, 31, 0.8);
  padding: 12px;
  border-radius: 0;
  position: relative;
  z-index: 7;
  color: #fff;
  margin-bottom: 0;
  margin-left: 0;
  height: 100%;
}

.hero-body-body p {
  color: #eee;
}
.hero-body-head h1 {
  text-transform: capitalize;
  font-size: 2em;
}

.welcome {
  position: relative;
  z-index: 5;
}

.welcome .hero-content-inner {
  margin-left: auto;
  background-color: transparent;
  width: 600px;
  height: 300px;
  margin: auto;
}
.welcome h1 {
  font-size: 4em;
  text-transform: uppercase;
  text-align: center;
  color: #0224ff;
  text-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
}
.welcome h1 span {
  color: #25d366;
  font-size: 20px !important;
  margin-bottom: 1em;
}
.hero-body-head p {
  font-size: 1.5em;
  color: #000;
  text-transform: uppercase;
}
.hero-body-head a {
  margin-top: 1.5em;
  display: block;
  text-transform: capitalize;
}
.ctas-container {
  justify-content: space-around;
  align-items: center;
}

.ctas-container .button {
  width: auto;
  text-transform: uppercase;
}

.subcta {
  background-color: #fff;
  color: #77d4fd;
}
.buttons-container {
  display: flex;
}
.buttons-container .button {
  margin: 1em;
}
.subcta-yellow {
  background-color: #fed507;
  color: #77d4fd;
}

.subcta-green {
  background-color: #77d4fd;
  color: #fff;
}
.subcta-lightgreen {
  background-color: #8ad169;
  color: #fff;
}

.hero-body ul {
  list-style: inside decimal;
}
.hero-body {
  text-align: center;
}
.slider-items-container {
  height: 100%;
  width: 100%;
  padding: 0;
}

.slider-items-container .slick-arrow {
  background-color: #8ad169 !important;
  border-radius: 2px;
  height: 32px;
  width: 50px;
  top: 50%;
}
.pdt-img-container.slider-items-container .slick-arrow {
  background-color: #77d4fd !important;
  width: 30px;
}
.carous .slick-arrow {
  background-color: transparent;
}

.slider-items-container .slick-arrow:hover {
  background-color: #000 !important;
}

.slider-items-container.center-slide-arrows .slick-arrow {
  top: 37% !important;
}

.pdt-img-container.slider-items-container.center-slide-arrows .slick-arrow {
  top: 50% !important;
}

.slider-items-container .slick-next,
.slider-items-container .slick-prev {
  z-index: 2;
}

.slider-items-container .slick-next {
  right: 0px;
}

.slider-items-container .slick-prev {
  left: 0px;
}

.carous .slider-items-container.center-slide-arrows .slick-arrow {
  top: 70% !important;
}
.carous .slider-items-container .slick-next {
  right: 20px;
}

.carous .slider-items-container .slick-prev {
  left: 20px;
}
.slider-items-container .slick-arrow.slick-disabled:before {
  opacity: 0;
}

.slider-items-container .slick-arrow.slick-disabled {
  background-color: transparent !important;
  cursor: auto !important;
}

.about-footer {
  width: 500px;
  text-align: left;
}

.about-footer img {
  width: 50px;
  background-color: #fed507;
}

.about-footer p {
  color: #eee;
  margin-top: 20px;
  text-align: justify;
}
.footer-menu-title {
  color: #77d4fd;
}
.footer-menu {
  justify-content: space-between;
  align-items: flex-start;
}
.footer-nav {
  text-align: left;
}

.footer-nav li {
  margin: 20px 0;
}
.footer-nav li a {
  text-decoration: none;
  color: #eee;
}
.footer-nav li a:hover {
  color: #fed507;
  text-decoration: underline;
}
.footer-nav.contact-us li {
  display: flex;
}

.footer-nav.contact-us li img {
  width: 20px;
  margin-right: 5px;
}

.footer-nav.contact-us li span {
  color: #fff;
}

.textpage-inner {
  justify-content: space-between;
  align-items: flex-start;
}
.textpage-text-side {
  width: 60%;
}

.textpage-text-side h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.textpage-text-side h3 {
  font-size: 1.1em;
  margin: 1em 0;
  margin-bottom: 8px;
}

.textpage-text-side p {
  margin-bottom: 1em;
  font-size: 1.1em;
}

.mission-side {
  width: 30%;
  background-color: #0224ff;
  color: #eee;
  padding: 0 1em;
}
.mission-item {
  border-top: 1px solid #fed507;
  display: flex;
  padding: 2em 8px;
}
.mission-item img {
  width: 40px;
}
.mission-item p {
  margin-top: 1em;
}
.mission-item h2 {
  font-size: 1.5em;
}

.textpage-text-model {
  border-left: 10px solid #77d4fd;
  background-color: #0224ff;
  padding: 1em;
}
.textpage-text-model p {
  margin-bottom: 0;
  color: #fff;
}
.results {
  background-color: #fed507;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/content/smartfarm4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.results-title {
  font-size: 1em;
  text-align: center;
}
.about-results-inner {
  justify-content: space-around;
  margin-top: 2em;
}
.about-results-item {
  background-color: transparent;
  color: #fff;
  padding: 1em;
  border-radius: 2px;
  text-align: center;
  width: 25%;
  border-right: 4px solid #fed507;
}
.about-results-item h5 {
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #77d4fd;
}

.about-contents {
  justify-content: space-between;
  align-items: center;
}
.about-text-side {
  width: 45%;
}
.about-image-side {
  width: 50%;
  align-items: center;
  justify-content: space-around;
}
.section-head h2.section-title {
  font-size: 3em;
  text-transform: uppercase;
  margin: 0.5em 0;
}
.section-head p {
  font-size: 1.5em;
  color: #000;
}
.about-img {
  border-radius: 2px;
  height: 400px;
  object-fit: cover;
}
.big-img {
  width: auto;
}
.small-img {
  width: 30%;
  margin-bottom: 100px;
}
.section-name {
  text-transform: uppercase;
}
.section-body p {
  font-size: 1.2em;
  margin: 1em 0 0 0;
}
.section-body ul.list-body-items li {
  margin: 10px 0;
  font-size: 1.2em;
  color: #000;
  display: flex;
  align-items: center;
}
.section-body ul.list-body-items li img {
  width: 20px;
  margin-right: 5px;
}
.section-body ul.list-body-items li span {
  margin-top: 5px;
}
.aligned-content-container {
  justify-content: space-around;
}
.spacing {
  width: 200px;
}
.aligned-item img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: contain;
}
.aligned-item h3 {
  color: #fff;
  font-size: 1.5em;
}

.aligned-item p {
  font-size: 1.2em;
  margin-top: 5px;
  color: #eee;
}
.aligned-item {
  align-items: center;
  padding-bottom: 3em;
  width: 350px;
}
.right-aligned-content .aligned-item img {
  order: 1;
  margin-left: 8px;
}
.left-aligned-content .aligned-item img {
  margin-right: 8px;
}
.right-aligned-content .aligned-item.pushed {
  margin-left: -3em;
}
.left-aligned-content .aligned-item.pushed {
  margin-left: 3em;
}

.right-aligned-content .aligned-item {
  text-align: right;
}
.about-snippet {
  margin: 1em 0;
  font-size: 3em;
}
.floating-image {
  position: absolute;
  width: 400px;
  height: 200px;
  left: 60px;
  top: 90%;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
}
.initiative-card {
  width: 100%;
  padding: 1em;
  margin-bottom: 5em;
}
.initiative-card .card-body {
  justify-content: space-between;
  align-items: center;
}
.initiative-card .card-body-text {
  width: 50%;
}
.initiative-card .card-image-side {
  width: 45%;
  background-color: #fed507;
}

.ordered.initiative-card .card-image-side {
  order: -1;
}

.ordered.initiative-card .card-body-text {
  text-align: right;
}

.services-cards-container,
.pdts-cards-container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}
.service-card {
  width: 45%;
  text-align: justify;
  margin-bottom: 4em;
  border: 2px solid #0224ff;
  border-top: 0;
  border-bottom: 0;
  padding: 8px;
  border-radius: 25%;
}
.service-card:last-child {
  margin-bottom: 2em;
}

.service-card h3 {
  color: #0224ff;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 2px solid #0224ff;
  text-transform: uppercase;
}
.service-card div {
  padding: 2em;
}

.service-card div p {
  margin-top: 10px;
}

.service-card img{
  width: 100%;
  object-fit: cover;
  height: 200px;
  border-radius: 25%;
}

.pdt-card > div {
  margin-bottom: 1em;
}
.pdt-card > div:last-child {
  margin-bottom: 0;
}
.slider-items-container.pdt-img-container {
  padding: 0 !important;
}
.service-card-image img,
.pdt-img img {
  height: 200px;
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
}
.pdt-img-container,
.pdt-img img {
  height: 150px;
}
.card-cta,
.pdt-cta {
  background-color: transparent;
  color: #77d4fd;
  text-align: center;
  display: block;
  cursor: pointer;
  text-decoration: underline;
}
.pdt-cta .card-cta {
  width: 100%;
}

.pdt-card {
  width: 300px;
  text-align: center;
  background-color: #eee;
  border-radius: 2px;
  padding: 4px;
  margin-bottom: 1.2em;
}
.pdt-img {
  position: relative;
}
.pname-span {
  position: absolute;
  bottom: 0;
  top: 87%;
  right: 0;
  left: 0;
  text-align: center;
  color: #fed507;
}
.initiative-card .card-image-side {
  width: auto;
  height: auto;
}
.contacts-container {
  justify-content: space-between;
}
.contact-item,
.contact-item-body {
  text-align: center;
}
.contact-item-head {
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}
.contact-item-head img {
  margin-right: 4px;
  width: 25px;
}
.contact-item-head h2 {
  font-size: 1em;
}
.map-container iframe {
  width: 100%;
  height: 300px;
}

.gallery-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.gallery-container figure {
  width: 30%;
  margin: 1em;
  border: 2px solid rgba(41, 69, 31, 0.3);
  padding: 10px;
  border-radius: 2px;
  background-color: lightyellow;
}

.gallery-container figure figcaption {
  font-size: 0.7em;
}

.farm-tour-video-container {
  width: 100%;
}

.team-container {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}
.team-container li {
  margin: 30px;
  width: 300px;
  padding: 1em;
}
.team-container li figure {
  background-color: #77d4fd;
  border-radius: 50%;
}
.team-container li figure img {
  border-radius: 8px;
}
.team-container li figure figcaption {
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  color: #fed507;
  background-color: #77d4fd;
  padding: 1em 0;
}
.floating-button-container {
  position: fixed;
  right: 10px;
  bottom: 20%;
  z-index: 100;
  text-align: right;
  overflow: hidden;
  width: 50px;
  height: 50px;
}
.whatsapp-button {
  background-color: #25d366;
  padding: 0;
  box-shadow: 16px 2px 2px 16px rgba(64, 29, 186, 0.5);
}

.whatsapp-button a {
  color: #fff !important;
  display: flex !important;
  align-items: center;
}
.whatsapp-button a img {
  line-height: 0;
  width: 50px;
  height: 50px;
}
.breeds-container {
  justify-content: space-around;
  flex-flow: row wrap;
}
.breed-card {
  width: 30%;
  padding: 8px;
  border: 8px solid #77d4fd;
  border-radius: 2px;
  background-color: #fed507;
  margin: 1em;
}
.breed-card h3 {
  margin: 1em 0;
}
.breed-card h4 {
  margin-bottom: 4px;
}
.breed-card p {
  font-size: 1em;
  color: #000;
  text-align: justify;
}
.breed-card img {
  height: 250px;
  object-fit: cover;
  object-position: center;
}
.breed-card div {
  margin: 1em 0;
}
.why-container {
  justify-content: center;
}
.why-image-side,
.why-text-side {
  width: 50%;
}
.why-image-side img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.why-text-side {
  padding: 1em;
}
.why-list-container {
  width: 100%;
  margin: auto;
}
.why-list-container li {
  display: flex;
  margin-left: 1em;
  margin-bottom: 2em;
}
.why-list-container li:last-child {
  margin-bottom: 0;
}
.why-list-container li img {
  width: 20px;
  margin-right: 10px;
}

.why-list-container li h3 {
  margin-bottom: 5px;
  font-size: 1em;
  color: #000;
}
.why-list-container li p {
  margin-top: 0;
}

.gallery-slider-container {
  height: 215px;
  padding-top: 3px;
}
.slider-container ul.slider-items-wrap {
  display: flex;
  align-items: center;
  padding: 0 0.5em;
}
.slider-container ul > li {
  text-align: center;
  list-style: none;
  cursor: pointer;
}

.slide .slide-image-container {
  border: 2px solid #77d4fd;
  border-right: 0;
  padding: 8px;
  background-color: #fed507;
}
.partners-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: end;
}
.partner-card {
  width: auto;
  max-width: 150px;
  margin: 20px;
}
.partner-card figcaption {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
}

.reso-container {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: flex-end;
}
.reso-card {
  width: 280px;
  margin: 20px;
}
.reso-card div {
  padding: 8px;
  border: 1px solid #fed507;
  border-top: 0;
  border-bottom: 0;
}
.reso-card h3 {
  text-transform: uppercase;
  padding: 10px;
  background-color: #77d4fd;
  color: #fff;
  text-align: center;
}
.reso-card p {
  margin: 10px 0;
  text-align: justify;
  font-size: 1em;
}
.reso-card a.button {
  background-color: #fed507;
  color: #77d4fd;
  width: 100%;
}

.fees-tables-container table {
  width: 100%;
}
.fees-table-item {
  margin: 40px 0;
}
.fees-table-item h3 {
  margin-bottom: 10px;
}

.fees-table,
.fees-table th,
.fees-table td {
  padding: 8px;
  border: 1px solid #000;
}
.fees-table th {
  color: #fed507;
  text-transform: uppercase;
}

.tution-table th {
  background-color: #77d4fd;
}

.hightlights-container {
  justify-content: space-around;
  flex-flow: row wrap;
}
.highlight-card {
  width: 30%;
  position: relative;
  margin: 1em;
}

.highlight-card a {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}

.highlight-card:after,
.hero-inner:after {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  transition: 0.3s ease;
}
.hero-inner:after {
  background-color: rgba(0, 0, 0, 0.1);
}
.highlight-card:hover:after,
.hero-inner:hover:after {
  background-color: rgba(0, 0, 0, 0.2);
}

.highlight-card h3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  color: #fff;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 2em;
}

.highlight-card a:hover h3 {
  color: #77d4fd;
}

.title {
  text-align: center;
  padding: 0 1em;
}

.highlights-title {
  text-align: center;
  font-size: 1em;
  padding: 0 1em;
}
.highlight-content-container {
  padding: 0 10px;
  color: #000;
}
.highlight-content-container h2 {
  margin-bottom: 1em;
  color: #000;
  font-size: 1.5em;
}
.highlight-content-container img {
  width: 100%;
}
.highlight-content-container figure {
  margin-bottom: 1em;
}
.highlight-content-container figure figcaption {
  font-size: 1em;
  text-align: center;
}

.highlight-content-container div p {
  margin-bottom: 10px;
}

.highlight-content-container div h3 {
  margin-top: 1em;
  margin-bottom: 10px;
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .welcome {
    padding-top: 100px;
  }
  .buttons-container {
    justify-content: center;
  }
  .service-card {
    width: 40%;
  }
  .gallery-container figure {
    min-width: 40%;
    max-width: 40%;
  }
  .top-contacts {
    width: 50%;
    flex-flow: column;
  }
  .topbar {
    height: 95px;
    padding: 0.5em 0;
  }
  .navbar {
    margin-top: 88px;
  }
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }
  .page-hero {
    margin-top: 170px;
  }
  .top-social a img {
    width: 30px;
  }
  .initiative-card .card-image-side {
    display: none;
  }
  .initiative-card .card-body-text {
    width: 100%;
  }

  .about-text-side {
    width: 50%;
    margin: auto;
    text-align: center;
  }

  .section-head h2.section-title {
    font-size: 2.5em;
  }
  .section-head p {
    font-size: 1.2em;
  }
  .spacing {
    width: 50px;
  }
  .aligned-item {
    width: 300px;
  }
  .about-footer {
    width: 300px;
  }
  .highlight-card {
    width: 45%;
  }
  .highlight-card h3 {
    font-size: 2.5em;
  }
}
@media screen and (min-width: 800px) and (max-width: 959px) {
  .welcome {
    padding-top: 100px;
  }
  .buttons-container {
    justify-content: center;
  }
  .top-contacts {
    width: 50%;
    flex-flow: column;
  }
  .topbar {
    height: 95px;
    padding: 0.5em 0;
  }
  .navbar {
    margin-top: 88px;
  }
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }

  .top-social a img {
    width: 25px;
  }
  .menu-side .main-menu > li {
    margin-right: 0.8em;
  }
  .about-text-side {
    width: 50%;
    margin: auto;
    text-align: center;
  }

  .section-head h2.section-title {
    font-size: 2em;
  }
  .section-body ul.list-body-items li {
    font-size: 1em;
    color: #000;
  }
  .spacing {
    width: 50px;
  }
  .aligned-item {
    width: 280px;
  }
  .right-aligned-content .aligned-item.pushed {
    margin-left: -2em;
  }
  .left-aligned-content .aligned-item.pushed {
    margin-left: 2em;
  }
  .menu-side ul.main-menu > li > a {
    font-size: 10px;
  }
  .hero-body-head h1 {
    font-size: 4em;
  }
  .hero-content-inner {
    width: 300px;
  }
  .breed-card {
    width: 60%;
  }
  .about-img {
    height: 300px;
  }
  .dog-bg {
    background-size: 200px;
  }
  .highlight-card {
    width: 48%;
  }
  .about-footer {
    width: 300px;
  }
  .section-head p {
    font-size: 1.2em;
  }
  .about-results-item h5 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  .about-results-item {
    width: 30%;
  }
  .footer-menu {
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .footer-menu-item {
    order: -1;
    width: 30%;
  }
  .footer-menu-item.about-footer {
    order: 1;
    width: 60%;
    margin-top: 2em;
  }
  .footer-menu-item.about-footer img {
    text-align: center;
    margin: auto;
    display: block;
  }

  .service-card {
    width: 45%;
  }
  .width-960 {
    width: 100%;
  }
  .page-hero {
    margin-top: 170px;
  }
  .textpage-text-side {
    width: 65%;
  }
  .mission-side {
    padding: 0 0.1em;
  }
  .initiative-card {
    padding-top: 0;
  }
  .initiative-card .card-body {
    flex-flow: column;
  }
  .initiative-card .card-body-text,
  .ordered.initiative-card .card-body-text,
  .initiative-card .card-image-side {
    width: 100%;
    text-align: center;
  }
  .initiative-card .card-image-side {
    order: -1;
    margin: 1em 0;
    height: 200px;
    display: none;
  }
  .initiative-card .card-image-side img {
    object-fit: cover;
    object-position: center;
  }
  .gallery-container figure {
    min-width: 40%;
    max-width: 40%;
  }
  .brand-side {
    font-size: 1em;
  }

  .highlight-card {
    width: 45%;
  }
  .highlight-card h3 {
    font-size: 2em;
  }
}
@media screen and (min-width: 600px) and (max-width: 799px) {
  .welcome {
    padding-top: 100px;
  }
  .buttons-container {
    justify-content: center;
  }
  .welcome .hero-content-inner {
    width: 90%;
    margin: auto;
  }
  .welcome h1 {
    font-size: 3em;
  }
  .highlight-card h3 {
    font-size: 3em;
  }
  .top-contacts {
    width: 50%;
    flex-flow: column;
  }
  .topbar {
    height: 95px;
    padding: 0.5em 0;
  }
  .navbar {
    margin-top: 88px;
  }
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }
  .menu-side .main-menu > li {
    margin-right: 1em;
  }
  .about-text-side {
    width: 50%;
    margin: auto;
    text-align: center;
  }

  .section-head h2.section-title {
    font-size: 1.5em;
  }
  .section-body ul.list-body-items li {
    font-size: 1em;
    color: #000;
    text-align: left;
  }
  .spacing {
    width: 10px;
  }
  .aligned-item {
    width: 250px;
  }
  .right-aligned-content .aligned-item.pushed {
    margin-left: -1em;
  }
  .left-aligned-content .aligned-item.pushed {
    margin-left: 1em;
  }
  .menu-side ul.main-menu > li > a {
    font-size: 10px;
  }
  .hero-body-head h1 {
    font-size: 4em;
  }
  .hero-content-inner {
    width: 300px;
  }
  .hero-body-head p {
    font-size: 1.3em;
  }
  .about-image-side {
    display: none;
  }
  .about-text-side {
    width: 80%;
  }
  .dog-bg {
    background-size: 150px;
  }
  .highlight-card {
    width: 80%;
  }
  .breed-card {
    width: 80%;
    max-width: 400px;
  }

  .about-footer {
    width: 300px;
  }
  .section-head p {
    font-size: 1.2em;
  }
  .about-results-item h5 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  .about-results-item {
    width: 30%;
  }

  .footer-menu {
    flex-flow: row wrap;
    justify-content: space-between;
    text-align: center;
  }
  .footer-menu-item {
    order: -1;
    width: 30%;
  }
  .footer-menu-item.about-footer {
    order: 1;
    width: 90%;
    margin-top: 2em;
  }
  .footer-menu-item.about-footer img {
    text-align: center;
    margin: auto;
    display: block;
  }
  .footer-menu-title,
  .about-footer p {
    text-align: center;
  }
  .footer-nav {
    text-align: center;
  }
  .footer-nav.contact-us {
    text-align: left;
  }
  .service-card {
    width: 80%;
  }
  .width-960 {
    width: 100%;
  }
  .width-600 {
    max-width: 600px;
    width: 100%;
  }
  .page-hero {
    margin-top: 170px;
  }
  .textpage-text-side {
    width: 65%;
  }
  .mission-side {
    padding: 0 0.1em;
    width: 100%;
    margin: 2em 0;
  }
  .initiative-card {
    padding-top: 0;
  }
  .initiative-card .card-body {
    flex-flow: column;
  }
  .initiative-card .card-body-text,
  .ordered.initiative-card .card-body-text,
  .initiative-card .card-image-side {
    width: 100%;
    text-align: center;
  }
  .initiative-card .card-image-side {
    order: -1;
    margin: 1em 0;
    height: 200px;
  }
  .initiative-card .card-image-side img {
    object-fit: cover;
    object-position: center;
  }
  .menu-side-inner {
    flex-flow: column;
    background-color: #fff;
    width: 150px;
    padding: 8px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
  }
  .main-menu {
    display: flex;
    flex-flow: column;
  }
  .menu-side .main-menu > li {
    margin: 1em 0;
  }
  .hide-responsive {
    display: block;
  }
  .nav-cta {
    margin-top: 1em;
    width: 100%;
  }
  .dropdown ul {
    right: 50%;
  }
  .nav-label {
    display: block;
    cursor: pointer;
  }
  .showParent {
    display: flex;
    animation: animatezoom 1s;
  }
  .hide-menu-btn {
    padding: 0;
    box-shadow: none;
    display: flex;
  }
  .pdt-card {
    width: 250px;
  }
  .textpage-inner,
  .contacts-container {
    flex-flow: column;
  }
  .textpage-text-side {
    width: 70%;
    margin: auto;
    text-align: justify;
  }
  .contact-item {
    margin-bottom: 2em;
  }
  .title {
    font-size: 24px;
  }
  .width-800 {
    width: 100%;
  }
  .gallery-container figure {
    min-width: 40%;
    max-width: 40%;
    margin: 0.5em;
  }
  .initiative-card .card-image-side,
  .ordered.initiative-card .card-image-side {
    display: none;
  }

  .why-container {
    justify-content: center;
    flex-flow: row wrap;
  }
  .why-text-side {
    order: -1;
    max-width: 600px;
    margin: auto;
    padding: 100px 0;
  }
  .why-image-side,
  .why-text-side {
    width: 100%;
  }
}
@media screen and (max-width: 599px) {
  .container {
    padding: 0 0.3em;
  }

  .topbar,
  .navbar {
    position: relative;
  }

  .topbar-contents {
    flex-flow: column;
  }
  .top-contacts {
    width: 100%;
    flex-flow: column;
  }
  .top-contacts-item span {
    font-size: 12px;
  }
  .topbar {
    height: 125px;
    padding: 0.5em 0;
    display: none;
  }
  .top-social {
    margin-top: 8px;
  }
  .navbar,
  .hero,
  .page-hero {
    margin-top: 0px;
  }
  .top-contacts-item.mid-item {
    margin: 8px 0;
    padding: 0;
    border: 0;
  }

  .about-text-side {
    width: 50%;
    margin: auto;
    text-align: center;
  }

  .section-head h2.section-title {
    font-size: 1.5em;
  }
  .section-body ul.list-body-items li {
    font-size: 1em;
    color: #000;
    text-align: left;
  }
  .spacing {
    display: none;
  }

  .aligned-item {
    width: 250px;
    flex-flow: column;
    padding-bottom: 2em;
    text-align: center;
  }
  .dog-bg {
    background-image: none;
  }
  .section-spacing-100 {
    padding: 50px 0;
  }
  .right-aligned-content .aligned-item,
  .left-aligned-content .aligned-item {
    text-align: center;
  }
  .aligned-content-container {
    justify-content: center;
    flex-flow: row wrap;
  }
  .right-aligned-content .aligned-item img {
    order: -1;
  }
  .right-aligned-content .aligned-item.pushed {
    margin-left: 0;
  }
  .left-aligned-content .aligned-item.pushed {
    margin-left: 0;
  }
  .menu-side ul.main-menu > li > a {
    font-size: 10px;
  }
  .hero-body-head h1 {
    font-size: 1.5em;
  }

  .breed-card {
    width: 100%;
  }
  .hero-logo {
    width: 50px;
  }
  .hero-body-head p {
    font-size: 12px;
  }
  .hide-responsive {
    display: block;
  }
  .section-head h2 {
    font-size: 2em;
  }
  .section-head p {
    font-size: 1.2em;
  }
  .about-contents {
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
  }
  .about-image-side {
    width: 100%;
    order: 2;
  }
  .about-text-side {
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .width-800,
  .width-600 {
    width: 100%;
  }
  .about-results-item h5 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  .about-results-inner {
    flex-flow: column;
  }
  .about-results-item {
    width: 100%;
    margin-bottom: 1em;
  }
  .footer-menu {
    flex-flow: row wrap;
    justify-content: center;
  }
  .footer-menu-item {
    order: -1;
    width: 100%;
    margin-bottom: 1em;
  }
  .footer-menu-item.about-footer {
    order: 1;
    width: 90%;
    margin-top: 2em;
  }
  .footer-menu-item.about-footer img {
    text-align: center;
    margin: auto;
    display: block;
  }
  .footer-menu-title,
  .about-footer p {
    text-align: center;
  }
  .footer-nav.contact-us li {
    justify-content: center;
  }
  .footer-nav {
    text-align: center;
  }
  .service-card {
    width: 100%;
  }
  .width-960 {
    width: 100%;
  }

  .textpage-text-side {
    width: 65%;
  }
  .mission-side {
    padding: 0 0.1em;
    width: 100%;
    margin: 2em 0;
  }
  .initiative-card {
    padding-top: 0;
  }
  .initiative-card .card-body {
    flex-flow: column;
  }
  .initiative-card .card-body-text,
  .ordered.initiative-card .card-body-text,
  .initiative-card .card-image-side {
    width: 100%;
    text-align: center;
  }
  .initiative-card .card-image-side {
    order: -1;
    margin: 1em 0;
    height: 200px;
  }
  .initiative-card .card-image-side img {
    object-fit: cover;
    object-position: center;
  }
  .menu-side-inner {
    flex-flow: column;
    background-color: #fff;
    width: 180px;
    padding: 8px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    justify-content: left;
  }
  .main-menu {
    display: flex;
    flex-flow: column;
  } 
  .menu-side .main-menu > li {
    margin: 0;
    border-top: 1px solid #77d4fd;
    padding: 1em 0;
  }
  .menu-side .main-menu > li:first-child {
    margin-top: 5px;
  }
  .menu-side .main-menu > li:last-child {
    border-bottom: 1px solid #77d4fd;
  }

  .nav-cta {
    margin-top: 1em;
    width: 100%;
    font-size: 12px;
  }
  .dropdown ul {
    right: 50%;
  }
  .nav-label {
    display: block;
    cursor: pointer;
  }
  .showParent {
    display: flex;
    animation: animatezoom 1s;
  }
  .hide-menu-btn {
    padding: 0;
    box-shadow: none;
    display: flex;
    width: 35px;
  }
  .pdt-card {
    width: 300px;
  }
  .textpage-inner,
  .contacts-container {
    flex-flow: column;
  }
  .textpage-text-side {
    width: 95%;
    margin: auto;
    text-align: center;
  }
  .contact-item {
    margin-bottom: 2em;
  }
  .title {
    font-size: 24px;
    text-align: center;
  }
  .gallery-container figure {
    width: 100%;
  }
  .initiative-card .card-image-side,
  .ordered.initiative-card .card-image-side {
    display: none;
  }
  .about-snippet {
    font-size: 2em;
  }

  .hero-body-head h1 {
    font-size: 1.3em;
  }
  .buttons-container {
    justify-content: center;
  }
  .welcome .hero-content-inner {
    width: 90%;
    margin: auto;
  }
  .welcome h1 {
    font-size: 3em;
  }
  .team-container li {
    margin: 12px 10px;
    width: 300px;
  }
  .floating-button-container {
    margin-right: 10%;
    margin-bottom: 30%;
    right: -25px;
    bottom: 0;
    top: 90%;
    overflow: hidden;
  }
  .big-img {
    display: none;
  }
  .highlight-card {
    width: 80%;
  }
  .carous .slider-items-container.center-slide-arrows .slick-arrow {
    top: 70% !important;
  }
  .carous .slider-items-container .slick-next {
    right: 5px;
  }
  .carous .slider-items-container .slick-prev {
    left: 5px;
  }
  .subcta-yellow {
    font-size: 10px;
  }
  .why-container {
    justify-content: center;
    flex-flow: row wrap;
  }
  .why-text-side {
    order: -1;
    margin: auto;
    padding: 100px 0;
  }
  .why-image-side,
  .why-text-side {
    width: 100%;
  }
  .hero-content-container {
    height: 60vh;
  }
  .welcome .hero-content-inner {
    width: 90%;
    height: 200px;
    background-color: transparent;
  }
  .highlight-card h3 {
    font-size: 1.2em;
  }
}
